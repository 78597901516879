<template>
    <v-app>
        <v-card style="margin:auto" max-width="400" class="login mx-auto elevation-12 corners">
            <v-row>
                <v-form id="login">
                    <v-col justify="center">
                        <v-card-text justify="center">
                            <v-row justify="center">
                                <div>
                                    <v-col style="margin-top:40px">
                                        <span class="d-flex login-header"> התחברות לאתר </span>
                                    </v-col>
                                </div>
                                
                                <v-col>
                                        <!-- <v-img :src="require('../assets/sb_logo.png')" contain height="100"></v-img> -->
                                        <v-img  class="logo" :src="require('../assets/sb_logo.png')" transition="scale-transition" contain></v-img>
                                    </v-col>
                             
                                <v-col class="login-email" cols="10">
                                    <v-text-field color="#4C0013" v-model="email" :rules="emailRules" label="אימייל*"
                                        placeholder="הכנס אימייל" required name="email" type="email"></v-text-field>
                                </v-col>
                                <v-col cols="10">
                                    <v-text-field color="#4C0013" v-model="password" :rules="passwordRoles" label="סיסמא*"
                                        placeholder="הכנס סיסמא" name="password" type="password"
                                        :type="showPassword ? 'text' : 'password'" required append-icon="">
                                        <template v-slot:append>
                                            <v-btn icon @click="eyePassword">
                                                <v-icon small :style="{ color: getPasswordIconColor() }">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-col>



                                <div v-if="showError" id="error" v-show="false"
                                    class="alert alert-danger d-flex align-items-center text-center" role="alert"
                                    style="border-radius: 10px"><v-icon class="mb-4 ms-1">mdi-alert-circle-outline</v-icon>
                                    <!-- <span class="material-symbols-outlined mb-4 ms-1">
                                        info
                                    </span> -->
                                    <p> האימייל או הסיסמא אינם נכונים </p>
                                </div>

                                <div class="spinner-container alert-danger d-flex text-center mb-2" v-if="serverErrorAPI"
                                    style="border-radius: 16px">
                                    <span class="material-symbols-outlined mt-6">
                                        info
                                    </span>
                                    <p class="mt-3"> עקב תקלה האתר הפסיק לעבוד, האתר ישוב לעבוד בדקות הקרובות...
                                    </p>
                                </div>


                                <!-- <v-btn @click="checklogin()" class="login-button" style="border-radius:20px">
                                    <span style="color:white;">התחבר
                                    </span>
                                    <div class="spinner-grow text-light" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </v-btn> -->
                                <v-btn @click="checklogin()" class="login-button" style="border-radius: 20px">
                                    <span v-if="!loading" style="color: white;">התחבר</span>
                                    <div v-if="loading" class="spinner-grow text-light" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </v-btn>


                            </v-row>
                        </v-card-text>
                    </v-col>
                    <!-- <v-col cols="6" class="login-col-right">
          <v-card-text justify="center" style="height:500px">
            <v-row justify="center">
              <span style class="login-right-span">Create Account</span>
              <span class="login-sub-span">You can join us to learn English and improve yourself</span>
              <v-btn class="login-singin-button" style="border-radius:20px">
                <span style="color:white">SIGN UP</span>
              </v-btn>
            </v-row>
          </v-card-text>
        </v-col> -->
                </v-form>
            </v-row>
        </v-card>
        <ul class="circles">
            <li v-for="item in animationArr" :key="item.count"></li>
        </ul>
        <Footer class="d-none"></Footer>
    </v-app>
</template>


<script>
import CryptoJS from 'crypto-js';

export default {
    data() {
        return {
            email: "",
            password: "",
            animationArr: [],
            showError: "",
            email: '',
            loading: false,
            serverErrorAPI: false,
            showPassword: false,
            emailRules: [
                v => !!v || 'כדי להתחבר צריך להכניס אימייל רשום',
                v => /.+@.+/.test(v) || 'האימייל חייב לכלול @ ',
            ],
            passwordRoles: [
                v => v.length >= 8 || 'הסיסמא חייבת לכלול לפחות 8 אותיות וספרות',
            ],
        };
    },

    methods: {
        async checklogin() {
            this.loading = true
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "email": this.email,
                "password": this.password
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch("https://satmarbakery.com:8443/users/login", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.name) {
                        try {
                            const secretKey = 'YourSecretKeyHere'; // Replace this with your own secret key
                            const userId = data.id; // Assuming 'id' is the user_id received from the server

                            // Encrypt the user_id
                            const encryptedUserId = CryptoJS.AES.encrypt(String(userId), secretKey).toString();
                            localStorage.setItem("user_id", encryptedUserId);


                            // Retrieve the encrypted_user_id from localStorage and verify
                            const storedEncryptedUserId = localStorage.getItem("user_id");

                            // this.$router.push({ name: 'home' });

                            const storedRoute = localStorage.getItem('previousRoute');
                            if (storedRoute) {
                                this.$router.push({ name: storedRoute });
                            } else {
                                this.$router.push({ name: 'home' }); // Default route when no stored route exists
                            }
                            localStorage.removeItem('previousRoute');
                            window.location.reload();

                            this.loading = false
                        } catch (error) {
                            this.showErrorAPI = true;
                            this.loading = false
                        }
                    } else {
                        this.showError = true;
                        this.loading = false
                    }
                })

                .catch((error) => {
                    console.error('Error fetching products:', error);
                    this.serverErrorAPI = true;
                    this.loading = false;
                });
        },


        //     fetch("https://satmarbakery.com:8443/users/login", requestOptions)
        //         // .then(response => response.json())
        //         .then((response) => response.json())
        //         .then((data) => {
        //             if (data.name) {
        //                 localStorage.setItem("user_id", data.id);
        //                 this.$router.push({ name: 'home' });
        //                 window.location.reload();
        //             } else {
        //                 this.showError = true;
        //             }
        //         })
        //         .catch(() => this.showError = true)


        // },
        // onLoginSuccess(user) {
        //     this.$store.dispatch('login', user)
        // }
        eyePassword() {
            this.showPassword = !this.showPassword;
        },
        getPasswordIconColor() {
            return this.showPassword ? '#4C0013' : '';
        },
    },

    created() {
        for (var count = 0; count < 10; count++) {
            let animationObj = {
                count: count
            };
            this.animationArr.push(animationObj);
        }
    }
};


</script>
<style scoped>
@import '../css/login.css';
</style>
