import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    count: 0,
    user: null
  },
  mutations: {
    increment (state) {
      state.count++
    },
    setUser (state, user) {
      state.user = user
    }
  },
  actions: {
    increment (context) {
      context.commit('increment')
    },
    login (context, user) {
      context.commit('setUser', user)
    },
    logout (context) {
      context.commit('setUser', null)
    }
  },
  getters: {
    count: state => state.count,
    user: state => state.user
  },
})

export default store