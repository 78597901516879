<template>
  <div class="store w-100">
    <!-- <MyCart> -->
    <header>
      <v-btn @click="navigateTo('products')" class="backANDdelete" title=" חזרה לחנות ">
        <v-icon v-if="$vuetify.breakpoint.smAndDown">mdi-arrow-right</v-icon>
        <span v-else><v-icon>mdi-arrow-right</v-icon> חזרה לחנות </span>
      </v-btn>

      <v-btn v-if="$vuetify.breakpoint.smAndDown" @click="navigateTo('cart')" title=" הסל שלי ">
        <v-icon size="16">mdi-cart</v-icon>
        <!-- <span v-if="!$vuetify.breakpoint.smAndDown">הסל שלי</span> -->
        <span v-if="totalQuantity > 0">({{ totalQuantity }})</span>
      </v-btn>

      <v-btn v-else @click="navigateTo('cart')">
        <v-icon>mdi-cart</v-icon>
        <span> הסל שלי ({{ totalQuantity }})</span>
      </v-btn>

      <div class="dropdown">
        <v-btn class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
          aria-expanded="false">
          <span v-if="$vuetify.breakpoint.smAndDown && cartLength > 0">קטגוריות</span>
          <span v-else>קטגוריית מוצרים</span>
        </v-btn>

        <ul class="dropdown-menu p-0  radius-5" aria-labelledby="dropdownMenuButton1">
          <div class="aaa p-1">
            <!-- <a class="dropdown-item" href="/"><u> כל המוצרים </u></a> -->
            <router-link class="dropdown-item" to="/store"><u> כל המוצרים</u></router-link>
            <router-link class="dropdown-item" to="/1">חלות</router-link>
            <router-link class="dropdown-item" to="/2">לחמניות</router-link>
            <router-link class="dropdown-item" to="/3">קוקטיילים</router-link>
            <router-link class="dropdown-item" to="/4">מאפים</router-link>
            <router-link class="dropdown-item" to="/5">עוגות בוקר</router-link>
            <router-link class="dropdown-item" to="/6">טורטים</router-link>
            <router-link class="dropdown-item" to="/7">מנה ראשונה</router-link>
            <router-link class="dropdown-item" to="/8">מנה אחרונה</router-link>
          </div>
        </ul>
      </div>


      <v-btn class="backANDdelete" v-if="$vuetify.breakpoint.smAndDown && totalQuantity > 0" @click="showDeleteDialog()"
        title=" מחיקת הזמנה ">
        <v-icon size="20">mdi-delete</v-icon>
      </v-btn>


      <v-btn v-if="!$vuetify.breakpoint.smAndDown && totalQuantity > 0" @click="showDeleteDialog()" title=" מחיקת הזמנה ">
        <v-icon small>mdi-trash-can</v-icon>
        מחיקת הזמנה
      </v-btn>

      <v-btn @click="navigateTo('payment')">
        <v-icon size="16">mdi-credit-card-outline </v-icon>
        לתשלום
      </v-btn>
      <!-- <v-btn v-if="$vuetify.breakpoint.smAndDown" @click="navigateTo('payment')">
        <v-icon size="16">mdi-credit-card-outline </v-icon>
        לתשלום
      </v-btn>
      <v-btn v-if="!$vuetify.breakpoint.smAndDown" @click="navigateTo('payment')">
        <v-icon>mdi-credit-card-outline </v-icon>
        ס"ה לתשלום
        {{ totalPrice }} ש"ח
      </v-btn> -->
      <!-- </div> -->

      <v-snackbar v-model="showSnackbar" class="mb-6" rounded="pill" color="#4C0013" :timeout="3000">{{ snackbarText }}</v-snackbar>

      <v-dialog v-model="deleteDialog" max-width="350">
        <v-card>
          <v-card-title class="headline center">מחיקת ההזמנה</v-card-title>
          <v-card-text>אתה בטוח שאתה רוצה למחוק את הסל הקניות?</v-card-text>
          <v-card-actions class="card-actions delete-dialog-actions">
            <v-btn text @click="confirmDelete" title=" מחיקת הזמנה ">מחיקה</v-btn>
            <v-btn color="grey darken-1" @click="cancelDelete" title="ביטול מחיקת הזמנה ">ביטול</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </header>
    <!-- </MyCart> -->

    <div v-if="page === 'cart'">
      <Cart v-on:removeItemFromCart="removeItemFromCart" :cart="cart" />
    </div>

    <div v-if="page === 'products'">
      <Products v-on:addItemToCart="addItemToCart" :products="filteredProducts" />
    </div>

    <div v-if="page === 'payment'">
      <Payment v-on:removeItemFromCart="removeItemFromCart" :cart="cart" />
    </div>
  </div>
</template>

<script>
import Products from "../components/Products.vue";
import Cart from "../components/Cart.vue";
import Payment from "../components/Payment.vue";
import { eventBus } from '../main';
export default {

  data: () => {
    return {
      page: "products",
      cart: [],
      payment: [],
      // MyCart: [],
      products: [],
      cartLength: 0,
      totalQuantity: 0,
      deleteDialog: false,
      editedProductToDelete: null,
      showSnackbar: false,
      snackbarText: "",
    };
  },
 
  components: { Products, Cart, Payment },

  // updated() {
  //   const cart = JSON.parse(localStorage.getItem('cart')) || [];
  //   let cartLength = 0;
  //   cart.forEach(item => {
  //     cartLength += item.quantity;
  //   });
  //   this.cartLength = cartLength;
  // },

  // created() {
  //   const existingCart = localStorage.getItem('cart');
  //   if (existingCart) {
  //     this.cart = JSON.parse(existingCart);
  //   }
  //   let cartLength = 0;
  //   this.cart.forEach(item => {
  //     cartLength += item.quantity;
  //   });
  //   this.cartLength = cartLength;
  // },
  methods: {
    calculateTotalQuantity() {
      let total = 0;
      const myCart = JSON.parse(localStorage.getItem('cart')) || {};

      for (const key in myCart) {
        if (myCart.hasOwnProperty(key)) {
          total += myCart[key].quantity;
        }
      }
      return total;
    },


    addItemToCart(product) {
      let existingProduct = this.cart.find(p => p.name === product.name);
      if (existingProduct) {
        existingProduct.quantity++;
        this.$set(existingProduct, 'quantity', existingProduct.quantity)
      } else {
        this.cart.push({ ...product, quantity: 1 });
      }
      // this.cart = cart;
      localStorage.setItem('cart', JSON.stringify(cart));
      eventBus.$emit('localStorageChanged');
    },

    removeItemFromCart(product) {
      let index = this.cart.indexOf(product);
      this.cart.splice(index, 1);
      eventBus.$emit('localStorageChanged');
    },
    removeItemFromCart(product) {
      let index = this.payment.indexOf(product);
      this.payment.splice(index, 1);
      eventBus.$emit('localStorageChanged');
    },


    navigateTo(page) {
      this.page = page;
    },


    showDeleteDialog() {
      if (this.totalQuantity > 0) {
        this.deleteDialog = true;
        this.editedProductToDelete = this.cart[0]; // You can choose any product to show in the dialog
      }
    },

    cancelDelete() {
      this.deleteDialog = false;
      // this.editedProductToDelete = null;
    },

    confirmDelete() {
      localStorage.removeItem('cart');
      this.deleteDialog = false;
      eventBus.$emit('localStorageChanged');
      this.showSnackbar = true;
      this.snackbarText = "הזמנה שלך נמחקה בהצלחה!";
      setTimeout(() => {
        this.showSnackbar = false;
        window.location.reload();
    }, 1000);
      // window.location.reload();
    },
  },

  computed: {
    // totalPrice() {
    //   let total = 0;
    //   for (let product of this.cart) {
    //     total += parseFloat(product.price) * product.quantity;
    //   }
    //   return total.toFixed(2);
    // },

    filteredProducts() {
      return this.products.filter(product => {
        return product.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },

  mounted() {
    this.totalQuantity = this.calculateTotalQuantity();

    eventBus.$on('localStorageChanged', () => {
      this.totalQuantity = this.calculateTotalQuantity();
    });
  },
  beforeDestroy() {
    eventBus.$off('localStorageChanged');
  }
};
</script>

<style scoped>
body {
  margin: 0;
}

header {
  height: 60px;
  box-shadow: 2px 2px 5px #4C0013;
  background-color: rgb(237, 198, 148);
  /* text-align: right; */
  /* font-size: 30px; */
  padding-top: 15px;
  position: sticky;
  top: 60px;
  z-index: 2;
  width: 100% !important;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.v-btn {
  background-color: #4C0013 !important;
  color: white !important;
  /* margin-left: 3px; */
  padding: 10px !important;
  margin-right: 8px;
}

.aaa {
  background-color: #4C0013;
}

.dropdown-item {
  color: #4C0013 !important;
  /* margin-left: -30px !important; */
  background-color: antiquewhite;
  padding-left: -100px !important;
}

.dropdown-item:hover {
  background-color: #4C0013;
  color: antiquewhite !important;

}

.delete-dialog-actions {
  flex-direction: row-reverse;
  justify-content: flex-start;
}

@media only screen and (max-width: 800px) {
  header {
    margin-right: -3%;
    width: 103% !important;
    top: 50px;
  }

  .backANDdelete {
    width: 40px !important;
    min-width: 40px !important;
  }

  .v-btn {
    padding: 6px !important;
    margin-right: 5px;
  }

}
</style>