<template>
    <v-layout column wrap="container" class="container">
        <v-row class="w-100 mb-5">
            <v-col cols="12" md="12" class="mt-4 ">
                <h1 class="h1"> סיום ההזמנה </h1>
            </v-col>
            <!-- <v-col cols="12" md="4" class="mt-6 me-0">
                <h5 class="total mt-2"><a href="#a"> ס"ה לתשלום {{ totalPrice }} ש"ח </a> </h5>
            </v-col> -->

            <hr>
        </v-row>
        <v-layout row wrap>
            <v-flex xs12 sm12 md8 class="p-0">
                <v-layout column>
                    <template>
                        <v-simple-table
                            :class="{ 'table': !$vuetify.breakpoint.smAndDown, 'table-small': $vuetify.breakpoint.smAndDown }">
                            <template v-slot:default>
                                <thead>

                                    <tr class="headerTable">
                                        <th class="header1"> מס'</th>
                                        <th class="header1 b-1"> שם המוצר </th>
                                        <th class="header1">כמות</th>
                                        <th class="header1 sm"> מחיר יחידה</th>
                                        <th class="header1"> מחיר ס"ה</th>
                                        <th class="header1"> הערות</th>
                                        <th class="header1 sm1"> עריכה/מחיקה</th>
                                    </tr>

                                </thead>

                                <tbody class="mt-4 " v-for="(product, index) in products" :key="index">
                                    <tr style="height: 45px !important;">

                                        <td>{{ index + 1 }}</td>
                                        <td>{{ product.name }}</td>
                                        <td>{{ product.quantity }}</td>
                                        <td class="sm">{{ product.price }}</td>
                                        <td>{{ (product.quantity * product.price).toFixed(2) }}</td>
                                        <td>
                                            <v-row class="input">
                                                <v-text-field v-model="notes[product.id]" @input="updateNote(product.id)"
                                                    :counter="50" color="gray" placeholder="הערות"></v-text-field>
                                            </v-row>
                                        </td>
                                        <td>
                                            <v-icon color="#4C0013" small @click="editProduct(product)">
                                                mdi-pencil
                                            </v-icon>
                                            <v-icon color="#4C0013" small @click="showDeleteDialog(product)" class="ms-1">
                                                mdi-delete
                                            </v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                                <div class="spinner-container" v-if="isLoading && !isCartEmpty">
                                    <Spinner :size="40" color="#4C0013"></Spinner>
                                </div>
                                <div class="spinner-container" v-if="serverError">
                                    עקב תקלה האתר הפסיק לעבוד, האתר ישוב לעבוד בדקות הקרובות...
                                </div>
                                <v-row v-if="isCartEmpty && !serverError">
                                    <h4 class="spinner-container mt-6 ">העגלה שלך ריקה</h4>
                                </v-row>
                                <div v-if="!isCartEmpty" class="footer">
                                    <h6> ס"ה לתשלום {{ calculateTotal() }} ש"ח</h6>
                                </div>
                            </template>
                        </v-simple-table>

                    </template>

                </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md4 class="delivery">
                <Delivery :calculateTotal="calculateTotal"></Delivery>
            </v-flex>

            <v-dialog v-model="editDialog" max-width="350">
                <v-card>
                    <v-card-title class="headline center" color="#4C0013">עריכת כמות</v-card-title>
                    <v-card-text>עריכת כמות של "{{ editedProduct.name }}"</v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field type="number" color="#4C0013" v-model="editedProduct.quantity"
                                    label="עריכת כמות"></v-text-field>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions class="card-actions">
                        <v-btn color="grey darken-1" text @click="cancelChanges">ביטול</v-btn>
                        <v-btn color="#4C0013 " text @click="saveChanges"> שמירה</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="deleteDialog" max-width="350">
                <v-card>
                    <v-card-title class="headline center"> מחיקת מוצר</v-card-title>
                    <v-card-text>אתה בטוח שאתה רוצה למחוק מסל "{{ editedProduct.name }}"?</v-card-text>
                    <v-card-actions class="card-actions">
                        <v-btn color="grey darken-1" text @click="deleteDialog = false">ביטול</v-btn>
                        <v-btn color="red darken-1" text @click="deleteProduct(editedProduct)">מחיקה</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>



        </v-layout>
    </v-layout>
</template>

<script>
import Delivery from './Delivery.vue';
import { eventBus } from '../main';
import Spinner from 'vue-spinner-component/src/Spinner.vue';


export default {
    // props: ["cart"],
    data() {
        return {
            products: [],
            cart: [],
            payment: [],
            editDialog: false,
            deleteDialog: false,
            editedProduct: {},
            serverError: false,
            notes: {},
        };
    },
    components: { Delivery, Spinner },
    // computed: {

    // },
    computed: {
        isCartEmpty() {
            const cart = JSON.parse(localStorage.getItem('cart')) || {};
            return Object.keys(cart).length === 0;
        },
        calculateTotal() {
            let total = 0;
            for (let product of this.products) {
                total += parseFloat(product.price) * product.quantity;
            }
            return () => total.toFixed(2);
            // return total.toFixed(2);
        }
    },

    methods: {

        editProduct(product) {
            this.editDialog = true;
            this.editedProduct = { ...product };
        },

        saveChanges() {
            const storedCart = JSON.parse(localStorage.getItem('cart')) || {};
            const productId = this.editedProduct.id.toString();

            // Convert the quantity to a number before saving
            const quantity = parseInt(this.editedProduct.quantity);

            if (storedCart[productId]) {
                storedCart[productId].quantity = quantity;
                localStorage.setItem('cart', JSON.stringify(storedCart));
            } else {
                storedCart[productId] = { quantity };
                localStorage.setItem('cart', JSON.stringify(storedCart));
            }
            this.editDialog = false;
            this.getAllProducts();
            eventBus.$emit('localStorageChanged');
        },

        updateNote(productId) {
            const storedCart = JSON.parse(localStorage.getItem('cart')) || {};
            const note = this.notes[productId] || '';

            if (storedCart[productId]) {
                storedCart[productId].note = note;
                localStorage.setItem('cart', JSON.stringify(storedCart));
            } else {
                storedCart[productId] = { ...this.editedProduct, note }; // Save the note along with other product details
                localStorage.setItem('cart', JSON.stringify(storedCart));
            }

            this.getAllProducts();
            eventBus.$emit('localStorageChanged');
            this.isLoading = false
        },

        cancelChanges() {
            this.editDialog = false;
        },

        showDeleteDialog(product) {
            this.editedProduct = product;
            this.deleteDialog = true;
        },

        updateLocalStorage() {
            localStorage.setItem('cart', JSON.stringify(this.cart));
        },
        deleteProduct(productToDelete) {
            const storedCart = JSON.parse(localStorage.getItem('cart')) || {};
            const productId = productToDelete.id.toString();

            if (storedCart[productId]) {
                delete storedCart[productId];
                eventBus.$emit('localStorageChanged');
                localStorage.setItem('cart', JSON.stringify(storedCart));
                this.deleteDialog = false;
                this.getAllProducts();
            }
            eventBus.$emit('localStorageChanged');
        },

        // cartLength() {
        //     return this.cart.reduce((total, item) => total + item.quantity, 0);
        // },
        transformStoredCart(storedCartObject) {
            const storedCartArray = Object.keys(storedCartObject).map((key) => ({
                id: parseInt(key), // Assuming ID is a number
                quantity: storedCartObject[key].quantity
            }));
            return storedCartArray;
        },
        updateProductQuantities(products, storedCart) {
            products.forEach((product) => {
                const productId = product.id.toString();
                const foundCartItem = storedCart.find(item => item.id.toString() === productId);
                if (foundCartItem) {
                    product.quantity = foundCartItem.quantity;
                } else {
                    product.quantity = 0; // Or default quantity if not found in local storage
                }
            });
        },
        getAllProducts() {
            this.isLoading = true;
            const storedCartObject = JSON.parse(localStorage.getItem('cart')) || {};
            const cartIds = Object.keys(storedCartObject);

            fetch("https://satmarbakery.com:8443/products")
                .then((response) => response.json())
                .then((jsonObject) => {
                    // Filter fetched products using cart IDs from local storage
                    this.products = jsonObject.filter(product => cartIds.includes(product.id.toString()));

                    const storedCartArray = this.transformStoredCart(storedCartObject);
                    this.updateProductQuantities(this.products, storedCartArray);
                    this.isLoading = false;
                    // this.isCartEmpty = true;
                })
                .catch((error) => {
                    console.error('Error fetching products:', error);
                    this.serverError = true;
                    this.isLoading = false;
                });
        }

    },
    created() {
        this.getAllProducts();

        const storedCart = JSON.parse(localStorage.getItem('cart')) || {};
        Object.keys(storedCart).forEach(productId => {
            this.$set(this.notes, productId, storedCart[productId].note || '');
        });


        eventBus.$on('localStorageChanged', () => {
            this.getAllProducts();
            this.$forceUpdate();
        });
    },
    beforeDestroy() {
        eventBus.$off('localStorageChanged');
    },


    // created() {
    //     for (let i = 0; i < localStorage.length; i++) {
    //         const key = localStorage.key(i);
    //         if (key.startsWith('cart_item_')) {
    //             const value = localStorage.getItem(key);
    //             this.cart.push({ id: key, quantity: parseInt(value) });
    //         }
    //     }
    //     const existingCart = localStorage.getItem('cart');
    //     if (existingCart) {
    //         this.cart = JSON.parse(existingCart);
    //     }
    // },

}
</script>
<style scoped>
.container {
    display: flex;
    /* width: 100% !important; */
    /* position: relative; */
    /* padding-left: 10% !important; */
    /* padding-right: 8% !important; */
}

.h1 {
    color: #4C0013 !important;
    text-align: center;
}

.border {
    border-radius: 5px;
    padding: 5px;
    color: #4C0013 !important;
    background-color: rgb(255, 247, 236);
    /* border: 1px solid #4C0013; */
}

a:hover {
    background-color: #4C0013;
    color: antiquewhite !important;

}

.headerTable {
    /* border: 1px solid !important; */
    border-radius: 18px !important;
}

.table {
    position: relative;
    background-color: rgb(246, 234, 219) !important;
    border: 1px solid #4C0013;
    border-radius: 8px !important;
    /* min-height: 515px; */
    min-height: 471px;
    height: auto;
}

.card-actions {
    justify-content: left;
}

.center {
    justify-content: center;
}

.header1 {
    color: #4C0013 !important;
}

.table-small {
    /* width: 95%; */
    font-size: 12px !important;
    background-color: rgb(246, 234, 219) !important;
    border: 1px solid #4C0013;
    border-radius: 8px;
}

.table-small td,
.table-small th {
    padding: 8px !important;
    border-bottom: 1px solid #4C0013;
    ;
}

.table-small th {
    text-align: center;
    margin: 10px !important;
    margin-left: 4vw !important;
    margin-bottom: 5vh;
}

.spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    padding: 20px;
    border-radius: 8px;
    font-size: 18px;
    /* color: #4C0013; */
    color: rgb(129, 129, 129);
    text-align: center;
}

.footer {
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #4C0013 !important;
}

@media (max-width: 950px) {
    .container {
        padding: 0.8vw !important;
        /* margin-right: -1.5vw; */

    }

    .border {
        margin: 1vw !important;
        margin-left: 4vw !important;
        border-radius: 12px;
        /* border: 1px solid #4C0013 !important; */
    }

    .table {
        position: relative;
    }

    .table-small {
        position: relative;
        min-height: 180px;
        height: auto;
    }

    .input {
        width: 21vw;
    }

    .total {
        width: 60%;
        margin: auto;
    }


    .footer {
        /* top: 99%; */
        top: 106% !important;
    }

    .delivery {
        margin-top: 20px;
    }

    .footer1 {
        transform: translate(-35%, 15%);
        width: 55%;
        background-color: #4C0013;
        color: antiquewhite;
        border-radius: 20px;
        padding: 4px;
        padding-right: 10px;
    }

    .sm {
        display: none;
    }

    .sm1 {
        font-size: 9px !important;
    }
}
</style>