<template>
  <v-list>
    <template>
      <v-list-item-content>
        <div class="profile">
          <div v-if="username" class="d-flex">
            <v-avatar size="42" class="profile-avatar"> {{ getInitials(username) }}</v-avatar>
            <div class="mt-4 ms-2">
              <p>{{ username }}</p>
            </div>
          </div>
          <v-list-item v-else to="/login" class="d-flex">
            <v-list-item-action>
              <v-avatar size="42" class="profile-avatar me-2">
                <v-icon>mdi-login</v-icon>
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>כניסה</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-content>
    </template>
    <v-list-item v-for="item in navItems" :key="item.title" :to="item.path" v-if="!item.showOnlyWhenLoggedIn || username">
      <v-list-item-action>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <template v-if="admin">
      <!-- <v-list-item :to="usersPath">
        <v-list-item-action>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> ניהול לקוחות </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item :to="managementPath">
        <v-list-item-action>
          <v-icon>mdi-lock-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> ניהול  </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-list-item v-if="isLoggedIn && username" @click="showLogOutDialog">
      <v-list-item-action>
        <v-icon>mdi-logout</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title> יציאה מאתר</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-dialog v-model="logoutDialog" max-width="350">
      <v-card>
        <v-card-title class="headline center">יציאה מאתר</v-card-title>
        <v-card-text>אתה בטוח שאתה רוצה לצאת מאתר?</v-card-text>
        <v-card-actions class="card-actions logout-dialog-actions">
          <v-btn text @click="logout" title=" אישור יציאה ">אישור</v-btn>
          <v-btn color="grey darken-1" @click="logoutCancel" title="ביטול יציאה מאתר ">ביטול</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list>
</template>

<script>
import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {
  data() {
    return {
      navItems: [
        {
          title: "דף הראשי",
          icon: "mdi-home",
          path: "/"
        },
        {
          title: "יצירת קשר",
          icon: "mdi-account",
          path: "/contactus"
        },
        {
          title: "היסטוריית הזמנות",
          icon: "mdi-history",
          path: "/history",
          showOnlyWhenLoggedIn: true
        },
      ],
      username: '',
      admin: false,
      isLoggedIn: true,
      managementPath: "/management",
      logoutDialog: false,
    };
  },
  async created() {
    await this.fetchUsername();
  },
  methods: {
    async fetchUsername() {
      const encryptedUserId = localStorage.getItem("user_id");
      if (encryptedUserId) {
        try {
          const secretKey = 'YourSecretKeyHere'; // Replace this with your own secret key
          const decryptedBytes = CryptoJS.AES.decrypt(encryptedUserId, secretKey);
          const userId = decryptedBytes.toString(CryptoJS.enc.Utf8);

          const response = await axios.get(`https://satmarbakery.com:8443/users/${userId}`);
          if (response && response.data && response.data.user && response.data.user.username) {
            this.username = response.data.user.username;
            this.admin = response.data.user.isAdmin === "1";
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    },

    logout() {
      localStorage.removeItem("user_id");
      this.username = '';
      this.admin = '';
      this.id = '';
      this.$emit('showSnackbar', { text: 'התנתקת מאתר בהצלחה!' });
      this.isLoggedIn = false;
      this.$emit('update:drawer', false);
      // setTimeout(() => {
        // window.location.reload();
      // }, 1000);
    },
    getInitials(name) {
      const initials = name.split(' ').map(word => word.charAt(0)).join('').toUpperCase();
      return initials;
    },
    showLogOutDialog() {
      this.logoutDialog = true;
    },
    logoutCancel() {
      this.$emit('update:drawer', false);
    },
  },
};
</script>

<style scoped>
.profile {
  display: flex;
  margin-top: 7vh;
  border-bottom: 1px solid white;
  margin-bottom: -4% !important;
}

.profile-avatar {
  margin-right: 11vw;
  border-radius: 50%;
  border: 2.5px solid;
  background-color: #66041c;
  color: white;
}

.profile-avatar:hover {
  color: #4C0013;
  background-color: white;
}
.logout-dialog-actions {
  flex-direction: row-reverse;
  justify-content: flex-start;
}
.v-btn {
  background-color: #4C0013 !important;
  color: white !important;
  margin-right: 8px;
}
.headline{
  color: #4C0013;
}

</style>
