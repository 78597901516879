import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import CryptoJS from 'crypto-js';
import axios from 'axios';


Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    component: () => import('../components/HelloWorld.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: HomeView,
    meta: { title: 'כניסה לאתר | מאפיית סאטמאר ' },
    component: () => import('../components/login.vue')
  },
  {
    path: '/management',
    name: 'management',
    meta: { requiresAuth: true, requiresAdmin: true, title: 'ניהול  | מאפיית סאטמאר '  }, 
    component: () => import('../components/management.vue')
  },
  {
    path: '/management/management-products',
    name: 'management-products',
    meta: { requiresAuth: true, requiresAdmin: true, title: 'ניהול מוצרים'  }, 
    component: () => import('../views/products.vue')
  },
  {
    path: '/management/management-users',
    name: 'management-users',
    meta: { requiresAuth: true, requiresAdmin: true ,title: 'ניהול לקוחות' },  
    component: () => import('../views/users.vue')
  },
  {
    path: '/contactus',
    name: 'contactus',
    meta: { title: 'יצירת קשר | מאפיית סאטמאר ' },
    component: HomeView,
    component: () => import('../components/contactus.vue')
  },
  {
    path: '/store',
    name: 'store',
    component: HomeView,
    component: () => import('../views/store.vue')
  },
  {
    path: '/history',
    name: 'history',
    meta: { requiresAuth: true , title: 'היסטוריית הזמנות | מאפיית סאטמאר '},
    component: () => import('../components/history.vue')
  },

  {
    name: 'category',
    path: '/:category',
    // component: store,
    // component:  '$route.params.category',
    component: () => import('../views/store.vue'),
  },

  // {
  //   path: '/payment',
  //   name: 'payment',
  //   meta: { title: ' סיום הזמנה | מאפיית סאטמאר '},
  //   component: () => import('../components/Payment.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = localStorage.getItem('user_id');

  if (to.meta.requiresAuth && !isLoggedIn) return next('/login');
  if (to.path === '/login' && isLoggedIn) return next('/');
  
  if (to.meta.requiresAdmin) {
    try {
      const encryptedUserId = localStorage.getItem('user_id');
      if (!encryptedUserId) throw new Error('User ID not found in localStorage');

      const secretKey = 'YourSecretKeyHere';
      const userId = CryptoJS.AES.decrypt(encryptedUserId, secretKey).toString(CryptoJS.enc.Utf8);
      const response = await axios.get(`https://satmarbakery.com:8443/users/${userId}`);
      const isAdmin = response?.data?.user?.isAdmin === '1';
      
      return isAdmin ? next() : next('/');
    } catch (error) {
      console.error('Error fetching user data:', error);
      return next('/');
    }
  }
  next();
});
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'מאפיית סאטמאר'; // Set a default title if no title is provided in the route meta
  }
  next();
});

export default router
