<template>
    <v-app-bar app color="#4C0013" dark>

        <router-link :to="'/'" class="d-flex align-center me-4">
            <v-img alt="Satmar Bekrey Logo" :src="require('../assets/satmar_bakery_logo.png')" transition="scale-transition"
                width="75" height="" class="shrink mr-2" />
        </router-link>

        <div v-if="showbtn" class="d-flex align-center">
            <div>
                <v-btn class="ms-2" :to="'/'" text>
                    דף הראשי
                </v-btn>
            </div>
            <div>
                <!-- <v-btn class="ms-2" href="#A" text> יצירת קשר -->

            </div>
            <div class="dropdown">
                <v-btn class=" dropdown-toggle" text type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                    aria-expanded="false"> קטגוריית מוצרים </v-btn>
                <ul class="dropdown-menu p-0 radius-5" aria-labelledby="dropdownMenuButton1">
                    <div class="aaa p-1">
                        <!-- <a class="dropdown-item" href="/store"><u> כל המוצרים </u></a> -->
                        <router-link class="dropdown-item" to="/store"><u> כל המוצרים</u></router-link>
                        <router-link class="dropdown-item" to="/1">חלות</router-link>
                        <router-link class="dropdown-item" to="/2">לחמניות</router-link>
                        <router-link class="dropdown-item" to="/3">קוקטיילים</router-link>
                        <router-link class="dropdown-item" to="/4">מאפים</router-link>
                        <router-link class="dropdown-item" to="/5">עוגות בוקר</router-link>
                        <router-link class="dropdown-item" to="/6">טורטים</router-link>
                        <router-link class="dropdown-item" to="/7">מנה ראשונה</router-link>
                        <router-link class="dropdown-item" to="/8">מנה אחרונה</router-link>
                    </div>

                </ul>
                <v-btn class="ms-2" :to="'/contactus'" text> יצירת קשר
                </v-btn>
            </div>
            <div v-if="admin">
                <v-btn class="ms-2" :to="'/management'" target="_blank" text>
                    ניהול 
                </v-btn>
            </div>
            <!-- <div v-if="admin">
                <v-btn class="ms-2" :to="'/management-products'" target="_blank" text>
                    ניהול מוצרים
                </v-btn>
            </div>
            <div v-if="admin">
                <v-btn class="ms-2" :to="'/management-users'" target="_blank" text>
                    ניהול לקוחות
                </v-btn>
            </div> -->
            <div v-if="username">
                <v-btn class="ms-2" :to="'/history'" text>
                    היסטוריית הזמנות
                </v-btn>
            </div>
            <div>
                <!-- <v-btn class="ms-2" href="#C" text> הסל שלי </v-btn> -->

                <!-- <MyCart /> -->
            </div>
        </div>
        <div v-if="showbtn" class="d-flex align-center ms-4 justify-start" style="position: fixed; left: 0;">
            <div v-if="username">
                <div v-if="username" v-show="true" class=" text--white d-flex align-items-center" role="alert">
                    <p class="username mt-4"> סוכן: {{ username }}</p>
                    <!-- <span v-if="username" v-show="true" class="material-symbols-outlined ms-2"> info </span> -->
                    <v-icon size="medium" class="me-1">mdi-account-circle-outline</v-icon>
                </div>
            </div>

            <template v-if="!username">
                <v-btn class="ms-2" :to="'/login'" text title=" כניסה לאתר ">
                    כניסה
                    <!-- <span class="material-symbols-outlined me-1">login</span> -->
                    <v-icon size="medium" class="me-1">mdi-login</v-icon>
                </v-btn>
            </template>
            <template v-else="username">
                <v-btn v-if="loggedIn" @click="showDeleteDialog" class="ms-2" text>
                    יציאה
                    <v-icon size="medium" class="me-1">mdi-login</v-icon>
                </v-btn>
            </template>
        </div>
        <v-dialog v-model="logoutDialog" max-width="350">
            <v-card>
                <v-card-title class="headline center">יציאה מאתר</v-card-title>
                <v-card-text>אתה בטוח שאתה רוצה לצאת מאתר?</v-card-text>
                <v-card-actions class="card-actions logout-dialog-actions">
                    <v-btn text @click="logout" title=" אישור יציאה ">אישור</v-btn>
                    <v-btn color="grey darken-1" @click="logoutCancel" title="ביטול יציאה מאתר ">ביטול</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div v-if="showsidebatbtn" class="d-flex align-center ml-2" style="position: fixed; left: 0%;">
            <v-btn @click="drawer = !drawer" text>
                <v-icon>
                    {{ drawer ? 'mdi-close' : 'mdi-menu' }}
                </v-icon>
            </v-btn>
        </div>
        <v-snackbar v-model="showSnackbar" class="showSnackbar" rounded="pill" color="#4C0013" :timeout="3000">{{
            snackbarText }}</v-snackbar>

        <v-navigation-drawer v-model="drawer" :temporary="false" class="sidebar">
            <sidebar @update:drawer="drawer = $event" @showSnackbar="showSnackbarFromSidebar" />
        </v-navigation-drawer>
    </v-app-bar>
</template>

<script>
import login from '../components/login.vue';
import sidebar from './sidebar.vue';
import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {
    components: {
        login,
        sidebar,
    },
    data() {
        return {
            email: "",
            password: "",
            username: "",
            admin: false,
            loggedIn: true,
            drawer: false,
            showSnackbar: false,
            snackbarText: "התנתקת מאתר בהצלחה!",
            logoutDialog: false,
        };
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        showbtn() {
            return !this.$vuetify.breakpoint.smAndDown;
        },
        showsidebatbtn() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },
    created() {
        this.fetchUsername();
    },
    methods: {
        async fetchUsername() {
            const encryptedUserId = localStorage.getItem("user_id");
            if (encryptedUserId) {
                try {
                    const secretKey = 'YourSecretKeyHere'; // Replace this with your own secret key
                    const decryptedBytes = CryptoJS.AES.decrypt(encryptedUserId, secretKey);
                    const userId = decryptedBytes.toString(CryptoJS.enc.Utf8);

                    const response = await axios.get(`https://satmarbakery.com:8443/users/${userId}`);
                    if (response && response.data && response.data.user && response.data.user.username) {
                        this.username = response.data.user.username;
                        this.admin = response.data.user.isAdmin === "1";
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        },

        async logout() {
            // this.showSnackbar = true;
            localStorage.removeItem("user_id");
            this.username = '';
            this.admin = '';
            this.id = '';
            // this.$emit('showSnackbar', { text: 'התנתקת מאתר בהצלחה!' });
            this.loggedIn = false;
            this.logoutDialog = false;
            this.showSnackbar = true;
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        },
        showSnackbarFromSidebar({ text }) {
            this.showSnackbar = true;
            this.snackbarText = text;
        },
        showDeleteDialog() {
            this.logoutDialog = true;
        },
        logoutCancel() {
            this.logoutDialog = false;
        },
    },
};
</script>


<style scoped>
.aaa {
    background-color: #4C0013;
}

.dropdown-item {
    color: #4C0013 !important;
    /* margin-left: -30px !important; */
    background-color: antiquewhite;
    padding-left: -100px !important;
}

.dropdown-item:hover {
    background-color: #4C0013;
    color: antiquewhite !important;

}

.username {
    font-size: small;
}

.showSnackbar {
    position: fixed;
    top: 50%;
    transform: translate(-0%, -50%);

}


.sidebar {
    position: fixed;
    margin-top: 55px;
    min-width: 60vw !important;
    width: auto !important;
    height: auto !important;
    background-color: #4C0013;
}

.logout-dialog-actions {
    flex-direction: row-reverse;
    justify-content: flex-start;
}

.v-btn {
    background-color: #4C0013 !important;
    color: white !important;
    margin-right: 8px;
}

.headline {
    color: #4C0013;
}</style>