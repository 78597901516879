<template>
  <v-col class="m text-center">
    <v-col cols="" class="mt-0">
      <v-row class="mb-6"> 
    
      <!-- <v-img :src="require('../assets/coffee_cake4.jpg')" height="600" width="100%" /> -->
      <v-img 
        :src="$vuetify.breakpoint.smAndDown ? require('../assets/coffee_cake1Sscreen.jpg') : require('../assets/coffee_cake4.jpg')"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '100%'"
        :height="$vuetify.breakpoint.smAndDown ? '20vh' : '600'"></v-img>
      </v-row>
    </v-col>
    <v-container >
     
      <div class="mainPage">
      <!-- <h1 class="header" :class="{ 'p': $vuetify.breakpoint.smAndDown }">Satmar Bakery</h1> -->
      <h4 class="header h4" :class="{ 'text-h4': $vuetify.breakpoint.smAndDown }">Satmar Bakery</h4>
      <h1><b> בקרוב ...</b></h1>
      <h2> ...coming soon </h2>
      <p>since 1998</p>

      <template>
        <v-row class="d-flex justify-center mt-6">
          <v-col cols="12" sm="12" md="12" class="pe-0">
            <v-carousel cycle class="carousel " hide-delimiter-background show-arrows-on-hover>
              <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.url" reverse-transition="fade-transition"
                transition="fade-transition"></v-carousel-item>
            </v-carousel>
          </v-col>

          <!-- <v-col cols="12" sm="0" md="0" class="ps-0 hidden-sm-and-down">
            <v-carousel cycle height="340" hide-delimiter-background show-arrows-on-hover>
              <v-carousel-item v-for="(item, i) in imags" :key="i" :src="item.url" reverse-transition="fade-transition"
              transition="fade-transition"></v-carousel-item>
            </v-carousel>
          </v-col> -->
        </v-row>
      </template>
    </div>
   
    </v-container>
    <!-- <NavCatgory ></NavCatgory> -->
    <div class="nav">
      <a name="products"></a>
      <MyCart></MyCart>
      <a name="B"></a>
    </div>
  </v-col>
</template>

<script>
// import NavCatgory from './NavCatgory.vue';
import MyCart from '../views/store.vue';
export default {
  components: {
    MyCart
  },
  name: 'HelloWorld',

  data() {
    return {
      items: [
        { url: require('../../server/public/uploads/cocktailH.jpg') },
        { url: require('../../server/public/uploads/Ushokko.jpg') },
        { url: require('../../server/public/uploads/soffle2.jpg') },
        { url: require('../../server/public/uploads/elegant.jpg') },
        { url: require('../../server/public/uploads/lachmania200g.jpg') },
      ],
      // imags: [

      //   { url: require('../../server/public/uploads/aleReshet.jpg') },
      //   { url: require('../../server/public/uploads/Uribba.jpg') },
      //   { url: require('../../server/public/uploads/pastetim.jpg') },
      // ],
    }
  },
}
</script>
<style scoped>
* {
  padding: 0;
  /* margin: 0%; */
}

.header {
  font-size: 500%
}

.carousel {
  max-width: 75% !important;
  margin: 0 auto;
  height: 70vh;
  border-radius: 10px !important;
}

@media screen and (max-width: 900px) {
  .carousel {
    max-width: 100% !important;
    border-radius: 0px !important;
    height: 40vh !important;
  }

}
</style>

