<template>
  <v-layout col class="container mainPage">
    <a name="C"></a>
    <v-row class="w-100">
      <h2 class="header">המוצרים שלי</h2>
      <hr>
    </v-row>
    <v-row v-if="isCartEmpty">
      <h4 class="mt-6" style="text-align: center;">העגלה שלך ריקה</h4>
    </v-row>
    <div class="spinner-container" v-if="isLoading">
      <Spinner :size="40" color="#4C0013"></Spinner>
    </div>


    <v-flex v-for="(product, index) in products" :key="index">
      <v-card class="the " height="100px" width="360px" :key="index">
        <v-responsive>
          <v-img :src="constructImageUrl(product.imagelink)" class="img" />
        </v-responsive>
        <v-col class="d-flex flex-column w-75">
          <div>
            <p class="p mb-0">{{ product.name + ' ' }}</p>
            <p class="price mt-0 mb-6">מחיר ליחידה: {{ product.price }}</p>
          </div>
          <v-col class="btn justify-space-between">
            <button class="add " v-on:click="addItemToCart(product)">+</button>
            <p class="input m-1">כמות: {{ product.quantity }}</p>
            <button class="add" v-on:click="removeItemFromCart(product)">-</button>
          </v-col>
        </v-col>
      </v-card>
    </v-flex>


    <div class="spinner-container" v-if="serverError">
      עקב תקלה האתר הפסיק לעבוד, האתר ישוב לעבוד בדקות הקרובות...
    </div>

    <!-- <div class="mainPage"></div> -->
    <!-- <Payment> -->
    <!-- <v-btn @click="payment" class="finish" :to="'/payment'">לסיום ההזמנה </v-btn> -->
    <!-- </Payment> -->
  </v-layout>
</template>


<script>
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import { eventBus } from '../main';

export default {
  components: {
    Spinner,
  },
  // mounted() {
  //   const savedCart = localStorage.getItem('cart');
  //   if (savedCart) {
  //     this.cart = JSON.parse(savedCart);
  //   }
  // },

  // props: ["cart"],
  data() {
    return {
      products: [],
      cart: [],
      // cart: {},
      isLoading: false,
      serverError: false,
    };
  },
  methods: {
    constructImageUrl(filename) {
      // Assuming your server is running on https://satmarbakery.com:8443
      const serverBaseUrl = 'https://satmarbakery.com:8443';
      return `${serverBaseUrl}/uploads/${filename}`;
    },
    // addItemToCart(product) {
    //   let cart = JSON.parse(localStorage.getItem('cart')) || {};
    //   if (cart[product.id]) {
    //     cart[product.id].quantity += 1;
    //   } else {
    //     cart[product.id] = { id: product.id, quantity: 1 };
    //   }
    //   localStorage.setItem('cart', JSON.stringify(cart));
    //   this.localCart = cart;
    // },

    // removeItemFromCart(product) {
    //   let cart = JSON.parse(localStorage.getItem('cart')) || {};
    //   if (cart[product.id] && cart[product.id].quantity > 0) {
    //     cart[product.id].quantity -= 1;
    //     if (cart[product.id].quantity === 0) {
    //       delete cart[product.id];
    //     }
    //     localStorage.setItem('cart', JSON.stringify(cart));
    //     this.localCart = cart;
    //   }
    // },

    addItemToCart(product) {
      let cart = JSON.parse(localStorage.getItem('cart')) || {};

      if (cart[product.id]) {
        cart[product.id].quantity += 1;
      } else {
        cart[product.id] = { id: product.id, quantity: 1 };
      }

      localStorage.setItem('cart', JSON.stringify(cart));
      // Find the product in this.products
      const index = this.products.findIndex(p => p.id === product.id);
      if (index !== -1) {
        // Update the quantity of the product in this.products
        this.$set(this.products[index], 'quantity', cart[product.id].quantity.toString());
      }
      eventBus.$emit('localStorageChanged');
      this.isLoading = false;
    },

    removeItemFromCart(product) {
      let cart = JSON.parse(localStorage.getItem('cart')) || {};

      if (cart[product.id] && cart[product.id].quantity > 0) {
        cart[product.id].quantity -= 1;

        if (cart[product.id].quantity === 0) {
          delete cart[product.id];

          // Remove the product from this.products array
          const index = this.products.findIndex(p => p.id === product.id);
          if (index !== -1) {
            this.products.splice(index, 1);
          }
        } else {
          const index = this.products.findIndex(p => p.id === product.id);
          if (index !== -1) {
            this.$set(this.products[index], 'quantity', cart[product.id].quantity.toString());
          }
        }
        localStorage.setItem('cart', JSON.stringify(cart)); // Update local storage
        eventBus.$emit('localStorageChanged');
        this.isLoading = false;
      }
    },

    getAllProducts() {
      this.isLoading = true;
      fetch("https://satmarbakery.com:8443/products")
        .then((response) => response.json())
        .then((jsonObject) => {
          const storedCart = JSON.parse(localStorage.getItem('cart')) || {};
          const selectedProductIds = Object.keys(storedCart);
          // 
          // Filter the products array to include only the selected products in the cart
          const selectedProducts = jsonObject.filter(product => selectedProductIds.includes(product.id.toString()));
          selectedProducts.forEach((product) => {
            product.quantity = storedCart[product.id].quantity.toString();
          });

          this.products = selectedProducts;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error('Error fetching products:', error);
          this.serverError = true;
          this.isLoading = false;
        });
    },

  },

  mounted() {
    this.getAllProducts();

    eventBus.$on('localStorageChanged', () => {
      this.getAllProducts();
    });
  },
  beforeDestroy() {
    eventBus.$off('localStorageChanged');
  },
  
  computed: {
    isCartEmpty() {
      const cart = JSON.parse(localStorage.getItem('cart')) || {};
      return Object.keys(cart).length === 0;
    },
  },

  // created() {
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     if (key.startsWith('cart_item_')) {
  //       const value = localStorage.getItem(key);
  //       this.localCart.push({ id: key, quantity: parseInt(value) });
  //     }
  //   }
  //   const existingCart = localStorage.getItem('cart');
  //   if (existingCart) {
  //     this.localCart = JSON.parse(existingCart);
  //   }
  // },

  // cartLength() {
  //   return this.cart.reduce((total, item) => total + item.quantity, 0);
  // },




}
</script>

<style scoped>
.header {
  text-align: center;
  font-size: 50px;
  margin: 20px;
  color: #4C0013;
  /* width: 100% !important; */
}

.container {
  display: flex;
  flex-wrap: wrap;
  /* gap: var(--gap, 2); */
  /* gap: 10px; */
  position: relative;
  padding-left: 18% !important;
  padding-right: 18% !important;
}

.the {
  display: flex;
  border: 1px solid !important;
  border-color: #4C0013;
  border-radius: 10px;
  background-color: #f5dbbc;
  /* width: 80%; */
  margin: 20px;
}

.the:hover {
  /* box-shadow: 1px 1px 5px  #4C0013 !important; */
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19) !important;
  background-color: #f5dbbc;
}

.mainPage {
  margin-bottom: 60vh;
}

.price {
  font-size: 12px;
  /* padding: 0; */
  /* margin-bottom: 0% !important; */
}

.img {
  border-radius: 10px;
  width: 150px;
  height: 99px;
}

.p {
  width: 150px !important;
  margin-top: -8px;
  height: 30px;
  /* overflow: hidden; */
}

.add {
  background-color: #4C0013 !important;
  color: white !important;
  border-radius: 12px;
  width: 35px !important;
  /* height: 40px; */
}

.btn {
  display: flex;
  background-color: #fbe5cb;
  padding: 0px;
  margin-top: -15px;
  width: 142px; 
  border-radius: 12px;
  border: 1px solid;

}

h4 {
  color: grey;
  /* margin-top: 20vh; */
  /* margin-bottom: 50vh; */
}
.input{
  font-size: small;
}

.spinner-container {
  width: 100%;
  height: 70vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #4C0013;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .container {
    /* padding-left: 3 !important; */
    padding-right: 0 !important;
    /* justify-content: center !important; */
  }

  .the {
    /* justify-content: center !important; */
    width: 100%;
    margin: 10px !important;
  }

  .img {
    width: 120px;
  }

  .price {
    font-size: 10px;
  }

  .btn {
    /* margin-right: 20px !important; */
    /* margin-top: 10px; */
    /* width: 80%; */
    margin-left: 0px !important;
  }

  .input {
    font-size: small !important;
  }
}
</style>