<template>
    <div class="form-wrapper">

        <v-form ref="form" class="form" v-model="valid" lazy-validation>
            <header class="header">
                <h4 class="header1"><u> פרטי הזמנה </u></h4>
            </header>
            <hr>
            <v-col class="input" color="#4C0013">
                <!-- <v-text-field :value="username" label="Username" ></v-text-field> -->
                <v-row>
                    <v-col cols="12" v-if="admin">

                        <!-- Dropdown list to select a user -->
                        <v-autocomplete v-model="selectedUser" :items="usersList" item-text="displayText" item-value="id"
                            label="בחר לקוח" color="#4C0013" @input="selectUser">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                        <!-- <v-text-field class="field" color="#4C0013"  v-model="name" :rules="nameRules" label="שם" -->
                        <!-- required> -->
                        <!-- </v-text-field>
                     -->
                        <v-text-field class="field" color="#4C0013" v-model="editedItem.username" :rules="nameRules"
                            label="שם*" required></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field class="field" color="#4C0013" v-model="editedItem.Phone" :rules="nameRules"
                            label="פלאפון*" required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field class="field" color="#4C0013" v-model="editedItem.Place" :rules="nameRules"
                            label="מיקום*" required></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field class="field" color="#4C0013" v-model="editedItem.Address" :rules="nameRules"
                            label="כתובת*" required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="date">
                        <v-text-field class="field" type="date" color="#4C0013" v-model="editedItem.Date" :rules="nameRules"
                            label="תאריך*" required :min="minDate"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select class="field" color="#4C0013" v-model="editedItem.Time" :rules="nameRules" label="שעה*"
                            required
                            :items="['7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',]">
                        </v-select>
                    </v-col>
                </v-row>
                <v-text-field :counter="50" color="gray" v-model="editedItem.Comment" placeholder="הערות"></v-text-field>
                
                
                <!-- <v-btn class="mr-4 mb-5" @click="reset">
                איפוס
            </v-btn> -->
            <!-- <v-btn @click="singup()" class="login-button mr-4 mb-5" style="border-radius: 18px">
                <span v-if="!loading" style="color: white;">אישור ההזמנה</span>
                <div v-if="loading" class="spinner-grow text-light" role="status">
                    <span class="visually-hidden"></span>
                </div>
            </v-btn> -->
            
            <div class="text-center mb-5">
                <v-btn class="delete mb-5" :disabled="calculateTotal() === '0.00'" @click="showDeleteDialog()"
                title=" מחיקת הזמנה ">
                    מחיקת הזמנה
                </v-btn>
                
                <v-btn @click="handleClick" :disabled="calculateTotal() === 0" class="singupBtn mr-4 mb-5">
                    <span v-if="calculateTotal() > 0 && !loading">אישור ההזמנה</span>
                    <span v-else title=" שים לב! העגלה שלך ריקה">אישור ההזמנה</span>
                    <div v-if="loading" class="spinner-grow text-light" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                </v-btn>
            </div>
        </v-col>
            
            <v-snackbar v-model="showSnackbar" class="showSnackbar" rounded="pill" color="#4C0013" :timeout="3000">{{
                snackbarText }}</v-snackbar>

            <div class="snackbar-container">
                <div text class="text-right" @click="$router.push('/history')">
                    <v-snackbar v-model="showSnackbar1" class="showSnackbar1" rounded="pill" color="#4C0013"
                        :timeout="9000">
                        <template v-slot:action="{ attrs }">
                            <v-btn v-bind="attrs" text class="text">
                                הזמנה שלך נוספה בהצלחה! <br> לצפייה בסטוטס הזמנה -<u class="u mt-4">לחץ כאן</u>
                            </v-btn>
                        </template>
                    </v-snackbar>
                </div>
            </div>

            <v-dialog v-model="deleteDialog" max-width="350">
                <v-card>
                    <v-card-title class="headline center">מחיקת ההזמנה</v-card-title>
                    <v-card-text>אתה בטוח שאתה רוצה למחוק את הסל הקניות?</v-card-text>
                    <v-card-actions class="card-actions delete-dialog-actions">
                        <v-btn class="deleteBtn" color="#4C0013" text @click="confirmDelete"
                            title=" מחיקת הזמנה ">מחיקה</v-btn>
                        <v-btn class="deleteBtn" color="grey darken-1" @click="cancelDelete"
                            title="ביטול מחיקת הזמנה ">ביטול</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <div v-if="!isLoggedIn" class="login-overlay">
                <div>
                    <p style="color: #4C0013;"> בכדי לסיים את ההזמנה, צריך קודם להיכנס לאתר</p>
                </div>
                <!-- <router-link to="/login" @click="storePreviousRoute('payment')"> -->
                <button class="login-btn" @click="storePreviousRoute"> כניסה לאתר </button>
                <!-- </router-link> -->
            </div>

        </v-form>
    </div>
</template>

  
<script>
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { eventBus } from '../main';


export default {
    data: () => ({
        valid: true,
        name: '',
        deleteDialog: false,
        loading: false,
        // username: '',
        // UserID: localStorage.getItem('id'),
        // user_id: localStorage.getItem('user_id'),
        address: '',
        nameRules: [
            v => !!v || 'שדה זה חובה',
        ],
        name: '',
        // phone: ''
        editedItem: {
            user_id: localStorage.getItem('user_id'),
            username: '',
            Phone: '',
            Place: '',
            Address: '',
            Date: '',
            Time: '',
            Comment: '',
        },
        showSnackbar: false,
        showSnackbar1: false,
        snackbarText: "",
        selectedUser: null,
        usersList: [],
        admin: false,
    }),

    props: {
        calculateTotal: {
            type: Function,
            required: true
        }
    },

    created() {
        const secretKey = 'YourSecretKeyHere'; // Replace this with your own secret key
        const encryptedUserId = localStorage.getItem('user_id');
        if (encryptedUserId) {
            const decryptedUserId = CryptoJS.AES.decrypt(encryptedUserId, secretKey).toString(CryptoJS.enc.Utf8);
            axios.get(`https://satmarbakery.com:8443/users/${decryptedUserId}`)
                .then(response => {
                    const userData = response.data.user;
                    this.editedItem.user_id = decryptedUserId;
                    this.editedItem.username = userData.username;
                    this.editedItem.Phone = userData.phone;
                    this.editedItem.Place = userData.Place;
                    this.editedItem.Address = userData.Address;
                    this.admin = response.data.user.isAdmin === "1";
                    this.fetchUsersList();

                    const deliveryInfo = localStorage.getItem('deliveryInfo');
                    if (deliveryInfo) {
                        const parsedDeliveryInfo = JSON.parse(deliveryInfo);
                        this.editedItem.Phone = parsedDeliveryInfo.Phone;
                        this.editedItem.Place = parsedDeliveryInfo.Place;
                        this.editedItem.Address = parsedDeliveryInfo.Address;
                        this.editedItem.Time = parsedDeliveryInfo.Time;
                        this.editedItem.Date = parsedDeliveryInfo.Date;
                        this.editedItem.Comment = parsedDeliveryInfo.Comment;
                    }

                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }
    },


    methods: {
        showDeleteDialog() {
            this.deleteDialog = true;
        },
        cancelDelete() {
            this.deleteDialog = false;
        },
        confirmDelete() {
            localStorage.removeItem('cart');
            this.deleteDialog = false;
            eventBus.$emit('localStorageChanged');
            this.showSnackbar = true;
            this.snackbarText = "הזמנה שלך נמחקה בהצלחה!";
            setTimeout(() => {
                this.showSnackbar = false;
                window.location.reload();
            }, 1000);
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },


        singup() {
            const valid = this.$refs.form.validate();
            if (valid) {
                this.loading = true
                const cartData = localStorage.getItem('cart');
                const productsObj = cartData ? JSON.parse(cartData) : {};
                const productsArray = Object.values(productsObj);

                const total = this.calculateTotal();

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");


                const encryptedUserId = localStorage.getItem('user_id');
                if (encryptedUserId) {
                    const secretKey = 'YourSecretKeyHere'; // Replace this with your secret key
                    const decryptedUserId = CryptoJS.AES.decrypt(encryptedUserId, secretKey).toString(CryptoJS.enc.Utf8);

                    // this.editedItem.user_id = decryptedUserId;
                    const isAdmin = this.admin && !this.selectedUser;

                    // Set user_id based on conditions
                    // const orderUserId = isAdmin ? decryptedUserId : this.selectedUser;  
                    const orderUserId = isAdmin ? decryptedUserId : this.selectedUser || decryptedUserId;
                    this.editedItem.Status = "1";


                    const payload = {
                        "user_id": orderUserId,
                        "username": this.editedItem.username,
                        "Phone": this.editedItem.Phone,
                        "Place": this.editedItem.Place,
                        "Address": this.editedItem.Address,
                        "Date": this.editedItem.Date,
                        "Time": this.editedItem.Time,
                        "Total": this.editedItem.Total = total,
                        "Comment": this.editedItem.Comment,
                        "Status": this.editedItem.Status,
                        Products: JSON.stringify(productsArray),
                    };
                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: JSON.stringify(payload),
                        redirect: 'follow',
                    };
                    // console.log('Data to be sent:', raw);
                    fetch("https://satmarbakery.com:8443/deliverys/singup", requestOptions)
                        .then(response => {
                            localStorage.removeItem('cart');
                            localStorage.removeItem('deliveryInfo');
                            eventBus.$emit('localStorageChanged');
                            // this.cart = [];
                            this.showSnackbar1 = true;
                            // window.location.reload();
                            // this.snackbarText = "הזמנה שלך נוספה בהצלחה!";
                            this.loading = false
                        })
                        .catch(error => {
                            console.error(error);
                            this.showSnackbar = true;
                            this.snackbarText = "קרתה תקלה בהזמנה נא לנסות מאוחר יותר";
                            this.loading = false
                        });
                }
            } else {
                this.showSnackbar = true;
                this.snackbarText = " שים לב! חסר פרטים בטופס";
                this.loading = false
            }

        },
        handleClick() {
            if (this.calculateTotal() > 0 && !this.loading) {
                this.singup();
            } else {
                this.showSnackbar = true;
                this.snackbarText = "שים לב! העגלה שלך ריקה";
            }
        },
        storePreviousRoute() {
            // localStorage.setItem('previousRoute', 'payment'); // Store the previous route
            this.$router.push({ name: 'login' });
        },
        fetchUsersList() {
            axios.get('https://satmarbakery.com:8443/users')
                .then(response => {
                    //             this.usersList = [
                    //     { id: null, username: null, phone: null, displayText: '(מספר לקוח) שם לקוח' }, 
                    //     ...response.data.map(user => ({
                    //         id: user.id,
                    //         username: user.username,
                    //         phone: user.phone,
                    //         displayText: `${user.username} (${user.id})`,
                    //     })),
                    // ];
                    this.usersList = response.data.map(user => ({
                        id: user.id,
                        username: user.username,
                        phone: user.phone,
                        Place: user.Place,
                        Address: user.Address,
                        displayText: `${user.idMain} - ${user.username}`,
                    }));
                })
                .catch(error => {
                    console.error('Error fetching users list:', error);
                });
        },

        selectUser() {
            const selectedUserInfo = this.usersList.find(user => user.id === this.selectedUser);
            if (selectedUserInfo) {
                this.editedItem.username = selectedUserInfo.username;
                this.editedItem.Phone = selectedUserInfo.phone;
                this.editedItem.Place = selectedUserInfo.Place;
                this.editedItem.Address = selectedUserInfo.Address;
            }
        },
    },
    computed: {
        // minDate() {
        //     const today = new Date().toISOString().substr(0, 10); // Get today's date in YYYY-MM-DD format
        //     return today;
        // },

        minDate() {
            // const today = new Date();
            // const currentHour = today.getHours();
            // const isAfterJerusalemTime = currentHour >= 19;
            // const daysToAdd = isAfterJerusalemTime ? 2 : 1;

            // const nextDay = new Date(today);
            // nextDay.setDate(today.getDate() + daysToAdd);

            // const minDate = nextDay.toISOString().substr(0, 10);

            // return minDate;
            const today = new Date();
            const currentHour = today.getHours();
            const isAfterJerusalemTime = currentHour >= 19;
            const daysToAdd = isAfterJerusalemTime ? 2 : 1;

            // Add extra days to avoid selecting Saturday
            let nextDay = new Date(today);
            nextDay.setDate(today.getDate() + daysToAdd);
            while (nextDay.getDay() === 6) { // Saturday (0-indexed)
                nextDay.setDate(nextDay.getDate() + 1); // Add one more day
            }

            const minDate = nextDay.toISOString().substr(0, 10);

            return minDate;

        },
        isLoggedIn() {
            return localStorage.getItem('user_id') !== null;
        },
    },
    mounted() {
        eventBus.$on('localStorageChanged', () => {
            // this.getAllProducts();
            this.$forceUpdate();
        });
    },
    beforeDestroy() {
        eventBus.$off('localStorageChanged');
    },


}
</script>

<style scoped>
.header1 {
    color: #4C0013 !important;
    text-align: center;
}

.form {
    position: relative;
    border: 1px solid #4C0013;
    border-radius: 8px;
    background-color: rgb(246, 234, 219) !important;
    margin-bottom: 5vh;
    /* min-height: 515px !important;  */
    min-height: 471px !important;
    height: auto;
}

.form-wrapper {
    position: relative;
    z-index: 1;
    /* Ensure the form is above the overlay */
}

.field {
    color: #4C0013 !important;
}

.date {
    width: 10% !important;
}

.delete {
    /* background-color: rgb(246, 234, 219) !important; */
    border: #4C0013 1px solid;
    border-radius: 18px;
    color: #4C0013;
}

.delete-dialog-actions {
    flex-direction: row-reverse;
    justify-content: flex-start;
}

.deleteBtn {
    background-color: #4C0013 !important;
    color: white !important;
    /* margin-left: 3px; */
    padding: 10px !important;
    margin-right: 8px;
}

.drop-list {
    background-color: #4C0013;
}

.login-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(246, 234, 219, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* Ensure it's above the form */
    flex-direction: column;
}

.login-btn {
    padding: 10px 20px;
    border: 1px solid #4C0013;
    border-radius: 5px;
    background-color: #4C0013;
    color: white;
    text-decoration: none;
}

.singupBtn {
    background-color: #4C0013 !important;
    color: white;
    border-radius: 18px;
}

.showSnackbar {
    margin-bottom: 5%;
}

.snackbar-container {
    position: relative;
    margin-bottom: -6vh !important;
}

.showSnackbar1 {
    position: absolute;
}

.u {
    margin-right: -0.6vw !important;
}

.text {
    text-align: center !important;
    margin-left: 10vh !important;
}

@media (max-width: 950px) {
    .form {
        margin: 5vw !important;
        margin-left: 7vw !important;
        margin-bottom: 4vh !important;
    }

    .header {
        margin-top: 14px !important;
    }

    .showSnackbar1 {
        position: fixed;
        margin-bottom: 10vh;
    }
}</style>