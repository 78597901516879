import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import googleAnalytics from './plugins/googleAnalytics';


Vue.config.productionTip = false

Vue.use(router);
Vue.use(store);
Vue.use(vuetify);
Vue.use(googleAnalytics); 

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')

// App.use(googleAnalytics);
// const eventBus = new Vue();
// Vue.mixin({
//   data() {
//     return {
//       eventBus
//     };
//   }
// });
export const eventBus = new Vue();