<template>
  <v-app>
    <NavBar></NavBar>
    
    <v-main>
      <router-view />
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';


export default {
  components: {
    NavBar,
    Footer,
  },
  name: 'App',
};
</script>
