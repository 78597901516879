export default {
    install(Vue) {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-FW5S2S3RHZ';
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', 'G-FW5S2S3RHZ');

        Vue.prototype.$gtag = gtag;
    }
};
