<template>
  <div class="container" :class="{ 'scrolled': isScrolled }">
    <v-layout col wrap="container" class="scroll">
      <v-row class="w-100">
        <V-col cols="1" sm="1"></V-col>
        <v-col cols="12" sm="6">
          <h4 class="header h4" :class="{ 'smaller-header': $vuetify.breakpoint.smAndDown }" v-html="headerTitle"></h4>

        </v-col>
        <v-col cols="8" sm="4" md="4" style="margin: auto;">
          <v-text-field class="search" v-model="search" type="search" color="#4C0013" append-icon="mdi-magnify"
            label="חיפוש מוצרים" single-line hide-details></v-text-field>
        </v-col>
        <hr>
        <v-col cols="12" class="scroll">
          <!-- <hr v-if="notFoundMessage"> -->
          <h1 class="msg" v-if="notFoundMessage">{{ notFoundMessage }}</h1>
        </v-col>
      </v-row>

      <div class="spinner-container" v-if="isLoading">
        <Spinner :size="40" color="#4C0013"></Spinner>
      </div>

      <v-flex class="theCart" v-for="(product, index) in filteredProducts" :key="index">
        <v-card flat class="cart ma-3" height="340px" width="260px" :key="index">
          <v-responsive>
            <v-img :src="constructImageUrl(product.imagelink)" class="img mt-1 mr-1 me-1" />
          </v-responsive>
          <v-card-text class="">
            <div class="cartName white--text">
              <v-row class="" :to="{ name: 'product', params: { id: product.id - 1 } }">
                <h4>{{ product.name }}</h4>
              </v-row>
            </div>
            <v-row class="body-2 mt-2 ">
              <v-col>
                <p>משקל: {{ product.weight + ' ' + product.kg }}</p>
              </v-col>
              <v-col>
                <p class="category">קטגוריה: {{ categoryMap[product.category] }} </p>
              </v-col>
            </v-row>
            <div class="text-center mt-1 body-2">מחיר: {{ product.price }} ש"ח ל{{ product.kg }}</div>

          </v-card-text>
          <v-card-actions class="center">
            <div class=" body-1">כמות:
              <!-- <v-text-field type="number" v-model="product.quantity" label="כמות"></v-text-field> -->
              <!-- <input type="number" v-model="product.quantity" class="w-50" placeholder=" 0"> -->
              <!-- <input type="number" v-model="product.quantity" @input="updateQuantity(product)" class="w-50" placeholder=" 0"> -->
              <input type="number" v-model="product.quantity" class="w-50" placeholder=" 0">

              <!-- <input type="number" v-model="product.quantity" @input="updateCart(product)" class="w-50" placeholder=" 0"> -->


            </div>
            <v-btn bottom color="#4C0013" class="white--text" @click="addItemToCart(product)">הוספה
              לסל
              <v-icon small left>mdi-cart</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <div class="spinner-container" v-if="serverError">
        <p>  עקב תקלה האתר הפסיק לעבוד, האתר ישוב לעבוד בדקות הקרובות... <br><br>
          <a href="/store"> לחץ כאן.</a> בכדי לרענן את הדף...</p>
      </div>

    </v-layout>
    <v-btn fab dark fixed bottom right x-small color="#4C0013" title="הקש כאן בכדי להגיע לתחילת הדף"
      class="scroll-to-top-btn" @click="scrollToTop">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import { eventBus } from '../main';

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      search: '',
      products: [],
      payment: [],
      cart: [],
      isLoading: false,
      serverError: false,
      isScrolled: false,
      notFoundMessage: "",
      categoryMap: {
        1: "חלות",
        2: "לחמניות",
        3: "קוקטיילים",
        4: "מאפים",
        5: "עוגות בוקר",
        6: "טורטים",
        7: "מנה ראשונה",
        8: "מנה אחרונה",
      },
    }
  },
  computed: {
    filteredProducts() {
      this.isLoading = true;
      let filteredProducts = this.products;
      let notFoundMessage = '';

      if (this.search) {
        filteredProducts = filteredProducts.filter(
          (product) =>
            product.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
        this.isLoading = false;
        if (filteredProducts.length === 0) {
          notFoundMessage = `לא נמצא מוצר "${this.search}" `;
        }
      } else if (this.$route.params.category) {
        filteredProducts = filteredProducts.filter(
          (product) => product.category === this.$route.params.category
        );
      }
      this.isLoading = false;
      this.notFoundMessage = notFoundMessage;
      return filteredProducts;
    },
    headerTitle() {
      switch (this.$route.name) {
        case 'home':
        case 'store':
          return '<b>כל הקטגוריות</b>';
        default:
          if (this.$route.params.category) {
            const category = this.categoryMap[this.$route.params.category];
            return category ? `<b>קטגוריה:</b> ${category}` : '<b>כל הקטגוריות</b>';
          }
          return '';
      }
    },
  },

  methods: {
    constructImageUrl(filename) {
      // Assuming your server is running on http://satmarbakery.com:8443
      const serverBaseUrl = 'https://satmarbakery.com:8443';
      return `${serverBaseUrl}/uploads/${filename}`;
    },


    //best code
    addItemToCart(product) {
      const cart = JSON.parse(localStorage.getItem('cart')) || {};
      const quantity = parseInt(product.quantity) || 0;
      // const quantity = cart[product.id] ? cart[product.id].quantity + 1 : 1;

      if (quantity <= 0) {
        if (cart[product.id]) {
          cart[product.id].quantity += 1;
        } else {
          cart[product.id] = {
            id: product.id,
            quantity: 1,
          };
        }
      } else {
        if (cart[product.id]) {
          cart[product.id].quantity = quantity;
        } else {
          cart[product.id] = {
            id: product.id,
            quantity: quantity,
          };
        }
      }

      localStorage.setItem('cart', JSON.stringify(cart));
      eventBus.$emit('localStorageChanged');
      this.isLoading = false;
    },

    getAllProducts() {
      this.isLoading = true;
      fetch("https://satmarbakery.com:8443/products")
        .then((response) => response.json())
        .then((jsonObject) => {
          const storedCart = JSON.parse(localStorage.getItem('cart')) || {};

          const filteredProducts = jsonObject.filter(product => product.productOn === '1');
          filteredProducts.forEach((product) => {
            // Check if the product exists in the cart and update the quantity
            if (storedCart[product.id]) {
              product.quantity = storedCart[product.id].quantity.toString();
            } else {
              product.quantity = '';
            }
          });
          // this.products = jsonObject;
          this.products = filteredProducts;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error('Error fetching products:', error);
          this.serverError = true;
          this.isLoading = false;
        });
    },
    scrollToTop() {
      const productContainer = document.querySelector('.scroll');
      const offset = 130;
      if (productContainer) {
        const topPosition = productContainer.getBoundingClientRect().top + window.scrollY;
        const targetScrollTop = topPosition - offset;

        window.scrollTo({ top: targetScrollTop, behavior: 'smooth' });
      } else {
        window.scrollTo(0, 0);
      }
    },
    handleScroll() {
      const productContainer = document.querySelector('.scroll');
      const scrollToTopBtn = document.querySelector('.scroll-to-top-btn');
      if (productContainer && scrollToTopBtn) {
        const containerTop = productContainer.getBoundingClientRect().top;
        const isScrolledPast = containerTop < 0;
        scrollToTopBtn.classList.toggle('visible', isScrolledPast);
      }
    },
  },
  mounted() {
    this.getAllProducts();

    eventBus.$on('localStorageChanged', () => {

    });
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
/* *{
  /* margin: 0; */

/* padding: 0; */
.scroll-to-top-btn {
  opacity: 0;
  transition: opacity 0.3s ease;
  right: 10vw;
}

.scroll-to-top-btn.visible {
  opacity: 1;
}

.scroll-to-top-btn:hover {
  box-shadow: 0px 0px 18px 3px rgb(0 0 0 / 30%);
}

.header {
  color: #4C0013;
  text-align: center;
  font-size: 50px;
}

.container {
  margin-top: 20px;
  position: relative;
  padding-left: 10vw !important;
  padding-right: 10vw !important;
}

.img {
  border-radius: 6%;
  height: 160px;
}

.center {
  /* display: flex; */
  margin-bottom: -10px !important;
  justify-content: center;
  margin-top: -10px !important;
}

.cart {
  background-color: #f5dbbc;
  /* border: 1px solid; */
  border-radius: 3%;
  /* z-index: 60px; */
}

.cart:hover {
  /* box-shadow: 1px 1px 5px  #4C0013 !important; */
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19) !important;
  background-color: #f5dbbc;
}

.cartName {
  color: #4C0013 !important;
}

input {
  background-color: #f7ebdc;
  border-radius: 8%;
}

.category {
  font-size: 11.5px;
}

.msg {
  text-align: center;
  color: #4C0013;
  margin-bottom: 540px;
}

.smaller-header {
  margin-top: 0px;
  font-size: 35px;
  margin-bottom: 0px;
}

.spinner-container {
  width: 100%;
  height: 70vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(249, 246, 243); */
  font-size: 18px;
  color: #4C0013;
  text-align: center;
}

@media only screen and (max-width: 400px) {
  .container {
    padding-right: 9vw !important;
  }
  .scroll-to-top-btn {
  right: 5vw;
}
}</style>